import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const StyledAccordion = styled((props) => (
    <MuiAccordion disableGutters  {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));
  
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'var(--primary-color)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    backgroundColor: theme.palette.background.paper2,
    padding: theme.spacing(2),
    borderTop: `1px dashed ${theme.palette.divider}`,
}));

const Accordion = (props) => {
    return (
        <StyledAccordion expanded={props.expanded} onChange={props.onChange}>
            <AccordionSummary>
                <Typography variant='h3'>{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.content}
            </AccordionDetails>
        </StyledAccordion>
    );
}

export default Accordion;
