import * as React from "react";
import { Grid, FormControl, InputLabel, Input, Button, Snackbar, Alert, Typography } from "@mui/material";

const axios = require('axios');

const defaultValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
};

const FormInput = (props) => {
    const name = props.name;
    const id = props.name.toLowerCase();

    return (
        <Grid item xs={props.xs}>
            <FormControl fullWidth>
                <InputLabel htmlFor={id}>{name}</InputLabel>
                <Input id={id} name={id} onChange={props.onChange} />
            </FormControl>
        </Grid>
    );
};

const Contact = () => {
    const [formValues, setFormValues] = React.useState(defaultValues);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [contactSuccess, setContactSuccess] = React.useState({status: 'error', message: 'An Error Occurred'});

    const submit = async (e) => {
        e.preventDefault();

        const {name, email, subject, message} = formValues;

        if(!name || !email || !subject || !message){
            setContactSuccess({status: 'warning', message:'Please fill in all fields'});
        }
        else{
            try{
                const response = await axios.post('/.netlify/functions/contact-me', formValues);

                setContactSuccess({status: 'success', message: response?.data});
            }
            catch(err){
                setContactSuccess({status: 'error', message: err?.response?.data});
            }
        }

        setSnackOpen(true);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    
    return (
        <Grid container spacing={2} sx={{maxInlineSize: '100vw', padding: '1rem'}}>
            <Grid item xs={12}>
                <Typography variant="h2">Contact me</Typography>
                <form onSubmit={submit} netlify='netlify'>
                    <Grid container spacing={2}>
                        <FormInput name="Name" onChange={handleInputChange} xs={6}/>
                        <FormInput name="Email" onChange={handleInputChange} xs={6}/>
                        <FormInput name="Subject" onChange={handleInputChange} xs={12}/>
                        <FormInput name="Message" onChange={handleInputChange} xs={12}/>

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" type="submit">Send Message</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>

            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={snackOpen} autoHideDuration={3000} onClose={() => setSnackOpen(false)} key={"snackbar"}>
                <Alert severity={contactSuccess.status} sx={{ width: "100%" }}>{contactSuccess.message}</Alert>
            </Snackbar>
        </Grid>
    );
}

export default Contact;
