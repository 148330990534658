import * as React from "react";
import { createTheme } from "@mui/material/styles";

const headingFont = (fontSize) => {
    return {
        fontFamily: 'var(--heading-font)',
        fontSize
    }
};

//shared theme stuff
const theme = {
    typography: {
        fontFamily: 'var(--body-font)',
        h1: headingFont('2em'),
        h2: headingFont('1.5em'),
        h3: headingFont('1.17em'),
        h4: headingFont('1em'),
        h5: headingFont('.83em'),
        h6: headingFont('.67em'),
        button: {fontSize: '1.2rem'}
    },
}

const LightTheme = createTheme({
    palette: {
        background: {
            default: '#ffffff',
            paper: '#cdd9e8',
            paper2: '#ffffff',
        },
    },
    ...theme,
});

const DarkTheme = createTheme({
    palette: {
        mode: 'dark',
        divider: '#282828',
        background: {
            default: '#181818',
            paper: '#1D1D1D',
            paper2: '#282828',
        },
    },
    ...theme,
});

//get dark mode setting from local storage or default to dark mode if undefined
const darkMode = localStorage.getItem('dark-mode') ?? 'true';
const Theme = darkMode === 'true' ? DarkTheme : LightTheme;

// console.log(Theme);

const ThemeContext = React.createContext({
    theme: Theme,
    setTheme: () => {}
});

export { ThemeContext, Theme, DarkTheme, LightTheme };