import * as React from "react";
import { Grid, Typography as Text } from "@mui/material";
import { styled } from "@mui/material/styles";

const Typography = styled(Text)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const About = () => {
    // const [test, setTest] = React.useState("hello world");
    // const {theme} = React.useContext(ThemeContext);

    return (
        <Grid container spacing={2} sx={{maxInlineSize: '100vw', padding: '1rem'}}>
            <Grid item xs={12}>
                <Typography variant="h2">About</Typography>
                <Typography variant="body1">
                    Hi there! I'm a software engineer with a strong foundation
                    in computer science principles, and I'm passionate about
                    applying my knowledge and skills to create innovative and
                    effective software solutions. In my free time, I enjoy a
                    variety of hobbies and interests. Cooking and baking are
                    among my favorites, and I often try out new recipes in the
                    kitchen. I'm also an avid musician, and I enjoy playing
                    guitar and singing. In addition, I have a keen interest in
                    3D printing and the latest developments in this field.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default About;
