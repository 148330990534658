import * as React from "react";
import { Typography, Container, } from "@mui/material";
import S1Logo from "./S1logo";
import EmployerEvaluation from "./EmployerEvaluation";

const WorkTerm2 = () => {
    // const [test, setTest] = React.useState("hello world");

    return (
        <Container>
            <S1Logo/>
            <Typography>This is a brief overview of the skills acquired and developed during my work term with System1 Canada.</Typography>
            <br />
            <Typography>System1 Canada is located in Guelph, Ontario with the System1 main office located in Venice, California. System1 is an advertising and publishing company with multiple offices across North America and Europe. System1 manages a number of websites including walletgenius.com, activebeat.com, fame10.com etc.</Typography>
            <br />
            <Typography>This was my second work term at System1 and my main goals for this term were to become more familiar with the WordPress site and theme structure, to further advance my expertise and knowledge of CSS and SASS, and also to further develop my critical thinking skills.</Typography>
            <br />
            <Typography>I developed many skills throughout this work term which included: developing WordPress themes, SCSS, various debugging, and QA methods. I also learned more about the Git workflow process and review process.</Typography>
            <br />
            <Typography>My work term as a Front End Developer at System1 involved working on several sites to fix bugs and implement new site features. I fixed various bugs including some display and formatting bugs but most notably I was involved in the development of System1's ‘Unified theme'. This was a WordPress theme which's intention was to unify different System1 websites into one theme in order to make the development and implementation of new features easier and more versatile. I had WordPress and PHP experience from my previous work term which was extremely beneficial during this term and allowed me to easily acquire the necessary skills and knowledge required for partaking in this theme's development.</Typography>
            <br />
            <Typography>Overall, I was able to increase my knowledge with WordPress and various other skills, and believe I will be able to advance these skills further and acquire new skills in the future.</Typography>
            <br />
            <EmployerEvaluation evaluation="Outstanding"/>
        </Container>
    );
}

export default WorkTerm2;
