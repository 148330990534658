import * as React from "react";
import { Grid, Typography as Text } from "@mui/material";
import { styled } from "@mui/material/styles";

const axios = require('axios');

const Typography = styled(Text)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const Resume = () => {
    const [resume, setResume] = React.useState(undefined);

    React.useEffect(() => {
        fetchResume();
    });

    const fetchResume = async () => {
        if(!resume){
            let response;

            try{
                response = require("./resume-cache.json");
            }
            catch(_){
                response = (await axios('/.netlify/functions/resume')).data;
                console.log('cache doesnt exist retrieving file from server');
            }

            setResume(response);
        }
    }

    const getBullets = (data = [], ignoreVariants = false) => {
        const arr = [];
        let variant;

        for(const [i, line] of data.entries()) {
            let style = { paddingInline: '0.25rem'};

            if(!ignoreVariants){
                if(line?.charAt(0) === "#"){
                    //body
                    variant = "body1";
                    style = {...style, paddingInline: '1rem'}
                }
                else if(variant === "h4"){
                    //subtitle
                    variant = "h5";
                    style = {...style, marginBottom: '0.1rem'}
                }
                else{
                    //title
                    variant = "h4";
                    style = {...style, marginTop: '0.8rem'}
                }
            }

            arr.push(<div key={`bullet-${i}`} style={style}><Typography variant={variant}>{line.replaceAll('#', '-')}</Typography></div>);
        }

        return arr;
    }

    const sectionStyle = {
        marginBlock: '0.25rem'
    }

    return (
        <Grid container spacing={2} sx={{maxInlineSize: '100vw', padding: '1rem'}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={sectionStyle}>Technical Skills</Typography>
                {getBullets(resume?.skills, true)}
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h2" sx={sectionStyle}>Education</Typography>
                {getBullets(resume?.education)}
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h2" sx={sectionStyle}>Work Experience</Typography>
                {getBullets(resume?.experience)}
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h2" sx={sectionStyle}>Projects</Typography>
                {getBullets(resume?.projects)}
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h2" sx={sectionStyle}>Coursework</Typography>
                {getBullets(resume?.coursework)}
            </Grid>
        </Grid>
    );
}

export default Resume;
