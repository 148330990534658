import * as React from "react";
import { Divider, Grid } from "@mui/material";
import About from "../about/About";
import Resume from "../resume/Resume";
import Work from "../work/Work";
import Contact from "../contact/Contact";
// import Home3D from "./Home3D";

const Home = () => {
    // const [test, setTest] = React.useState("hello world");

    return (
        <Grid container spacing={2} sx={{maxInlineSize: '100vw' }}>
            <Grid item xs={12}>
                <h2 style={{margin: '1rem'}}>Hello World!</h2>
                {/* <Home3D/> */}
                <Divider/>
                <About/>
                <Divider/>
                <Resume/>
                <Divider/>
                <Work/>
                <Divider/>
                <Contact/>
            </Grid>
        </Grid>
    );
}

export default Home;
