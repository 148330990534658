import * as React from "react";
import { Stack, Typography } from "@mui/material";

const EmployerEvaluation = (props) => {
    return (
        <Stack direction="row" justifyContent="flex-end">
            <Typography>Employer Evaluation: {props.evaluation}</Typography>
        </Stack>
    );
};

export default EmployerEvaluation;
