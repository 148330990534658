import * as React from "react";
import { Stack } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin as linkedinIcon, faGithub as githubIcon, faTwitter as twitterIcon } from '@fortawesome/free-brands-svg-icons';
import { ThemeContext } from "../ThemeContext";
import { styled } from "@mui/material/styles";

const SocialLinks = () => {
    const {theme} = React.useContext(ThemeContext);

    const Link = styled('a')(() => ({
        color: theme.palette.text.primary,
    }));

    return (
        <Stack direction='row' spacing={1} justifyContent='space-around'>
            <Link href='https://www.linkedin.com/in/shaquille-sadiq/' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={linkedinIcon}/></Link>
            <Link href='https://github.com/ShaquilleSadiq/' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={githubIcon}/></Link>
            <Link href='https://twitter.com/ShaquilleSadiq' target='_blank' rel="noreferrer"><FontAwesomeIcon icon={twitterIcon}/></Link>
        </Stack>
    );
}

export default SocialLinks;
