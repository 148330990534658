import * as React from "react";
import { Typography, Container } from "@mui/material";
import S1Logo from "./S1logo";
import EmployerEvaluation from "./EmployerEvaluation";

const WorkTerm3 = () => {
    // const [test, setTest] = React.useState("hello world");

    return (
        <Container>
            <S1Logo/>
            <Typography>This is a brief overview of the skills acquired and developed during my final work term with System1 Canada.</Typography>
            <br />
            <Typography>System1 Canada is located in Guelph, Ontario and the System1 HQ is located in Marina del Rey, California. System1 is an advertising and publishing company with multiple offices across North America and Europe. System1 manages a number of websites including walletgenius.com, activebeat.com, fame10.com etc.</Typography>
            <br />
            <Typography>This was my last work term at System1 and my main goals for this term were to become more familiar with ES6 JavaScript.</Typography>
            <br />
            <Typography>I developed many skills and learned a lot from this work term. I gained a deeper understanding of certain AWS technologies such as S3 storage and CloudFront distributions even though I didn't work with them directly. My position at System1 involved the development and styling of new page templates.</Typography>
            <br />
            <Typography>I used CSS to style various pages and create some animations. I became more familiar with CSS grids and learnt about logical properties. Towards the end of my work term, I developed a vehicle SERP which flexed and tested the majority of skills that I acquired throughout my work terms at System1, including SCSS, PHP, Alpine.js, ES6 JavaScript, CSS animations, JSON and some others.</Typography>
            <br />
            <Typography>Overall, I believe I was able to achieve my goals and was able to further my knowledge of skills I was already familiar with and also acquire knowledge of new technologies.</Typography>
            <br />
            <EmployerEvaluation evaluation="Outstanding"/>
        </Container>
    );
}

export default WorkTerm3;
