import * as React from "react";
import { Stack } from "@mui/material";
import Header from "../components/header/Header";
import { ThemeContext } from "../components/ThemeContext";

const ShaquilleSadiq = (props) => {
    const {theme} = React.useContext(ThemeContext);

    const pageStyle = {
        backgroundColor: theme.palette.background.default,
        minBlockSize: '100vh',
        maxInlineSize: '100vw',
        transition: '0.5s'
    };

    return (
        <Stack direction={{ xs: "column", md: "row" }} sx={pageStyle}>
            <Header key='header'/>
            {props.children}
        </Stack>
    );
}

export default ShaquilleSadiq;
