import * as React from "react";
import { Stack, IconButton, Dialog, Slide, Divider } from "@mui/material";
import { Link } from 'react-router-dom';
import logoP1Light from "../../assets/logo/1-light.png";
import logoP1Dark from "../../assets/logo/1-dark.png";
import logoP2 from "../../assets/logo/2.png";
import SocialLinks from "./SocialLinks";
import DarkModeToggle from "../DarkModeToggle";
import { ThemeContext } from "../ThemeContext";
import { styled } from "@mui/material/styles";
import { Menu as MenuIcon, Close as CloseIcon} from '@mui/icons-material/';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="down" ref={ref} {...props} />;
});

const RouterLink = styled(Link)(({theme}) => ({
    color: theme.palette.text.primary,
}));

const Header = () => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const {theme} = React.useContext(ThemeContext);

    const border = `1px solid ${theme.palette.divider}`;

    const headerStyle = {
        backgroundColor: theme.palette.background.paper,
        borderBlockEnd: {xs: border, md: 'unset'},
        borderInlineEnd: {md: border},
        boxSizing: 'border-box',
        paddingBlock: '0.5rem',
        position: 'sticky',
        textAlign: 'center',
        transition: '0.5s',
        top: '0',
        zIndex: 100
    };

    const logoStyle = {
        height: {xs: '50px', md: '100px'},
        width: {xs: '50px', md: '100px'},
    }

    const divider = <Divider orientation="horizontal" flexItem />;

    const menuitems = (
        <>
            <Stack direction="column" divider={divider} spacing={2}>
                <RouterLink to='/about' onClick={() => setMenuOpen(false)}>About</RouterLink>
                <RouterLink to='/resume' onClick={() => setMenuOpen(false)}>Skills</RouterLink>
                <RouterLink to='/work' onClick={() => setMenuOpen(false)}>Work</RouterLink>
                <RouterLink to='/contact' onClick={() => setMenuOpen(false)}>Contact</RouterLink>
            </Stack>
            <Stack direction="column" divider={divider} spacing={2} alignItems='center' sx={{marginTop: 'auto !important'}}>
                <SocialLinks/>
                <DarkModeToggle/>
            </Stack>
        </>
    );

    const desktopHeader = (
        <Stack direction="column" divider={divider} spacing={2} sx={{display: {xs: 'none', md: 'inherit'}, height: '100vh', ...headerStyle}} alignItems='center'>
            <RouterLink className='logo' to='/' sx={logoStyle}>
                <img className="logo__part1" src={theme?.palette?.mode === 'dark' ? logoP1Light : logoP1Dark} alt="logo"/>
                <img className="logo__part2" src={logoP2} alt="logo"/>
            </RouterLink>
            {menuitems}
        </Stack>
    );

    const mobileHeader = (
        <Stack direction="row" spacing={2} sx={{display: {md: 'none'}, ...headerStyle, boxShadow: menuOpen ? 'unset' : theme.shadows[4]}} justifyContent="space-between">
            <RouterLink className='logo' to='/' sx={logoStyle} onClick={() => setMenuOpen(false)}>
                <img className="logo__part1" src={theme?.palette?.mode === 'dark' ? logoP1Light : logoP1Dark} alt="logo"/>
                <img className="logo__part2" src={logoP2} alt="logo"/>
            </RouterLink>
            <IconButton sx={{color: theme.palette.text.primary}} aria-label="show menu" onClick={() => setMenuOpen(!menuOpen)}>
                {menuOpen ? <CloseIcon/> : <MenuIcon/>}
            </IconButton>
        </Stack>
    );

    const menuStyle = {
        backgroundColor: theme.palette.background.default,
        height: '100%',
        padding: '0.5rem',
        textAlign: 'center',
        transition: '0.5s',
    }

    const mobileMenu = (
        <Dialog fullScreen open={menuOpen} onClose={() => setMenuOpen(false)} sx={{display: {md: 'none'}}} TransitionComponent={Transition}>
            {mobileHeader}

            <Stack direction="column" divider={divider} spacing={2} sx={menuStyle} alignItems='stretch'>
            {menuitems}
            </Stack>
        </Dialog>
    );

    return (
        <>
            {desktopHeader}
            {mobileHeader}
            {mobileMenu}
        </>
    );
}

export default Header;
