import * as React from "react";
import { Stack } from "@mui/material";
import S1LogoWhite from "../../../assets/s1/s1-logo-white.png";
import S1LogoBlack from "../../../assets/s1/s1-logo-black.png";
import { ThemeContext } from "../../../components/ThemeContext";

const S1logo = () => {
    const {theme} = React.useContext(ThemeContext);

    return (
        <Stack direction='row' justifyContent='center' sx={{marginBlock: '0.5rem', height: {xs: '30px', md: '40px'}}}>
            <img src={theme?.palette?.mode === 'dark' ? S1LogoWhite : S1LogoBlack} alt="system1 logo"/>
        </Stack>
    );
}

export default S1logo;
