import * as React from "react";
import { Typography, Container, } from "@mui/material";
import S1Logo from "./S1logo";
import EmployerEvaluation from "./EmployerEvaluation";

const WorkTerm1 = () => {
    // const [test, setTest] = React.useState("hello world");

    return (
        <Container>
            <S1Logo/>
            <Typography>This is a brief overview of the skills acquired and what I accomplished during my work term with Qool Media Holdings.</Typography>
            <br />
            <Typography>Qool Media Holdings (System1 Canada) is a subsidiary of System1. It's located in Guelph, Ontario with the System1 main office located in Venice, California. System1 is an advertising and publishing company with multiple offices across North America.</Typography>
            <br />
            <Typography>My main goals for this work term were to learn PHP/WordPress, to become better at socializing and interacting with people I'm not well acquainted with, and to be able to manage and juggle multiple tasks.</Typography>
            <br />
            <Typography>I developed many skills throughout this work term which include: participating in the sprint process, how to work with WordPress/PHP, SCSS, SQL, various debugging, and QA methods. I also learned more about the Git workflow process and review process.</Typography>
            <br />
            <Typography>This will be beneficial in my next work term because I will have had prior experience and knowledge in working in Web/Software development.</Typography>
            <br />
            <Typography>My work term as a Front End Developer at System1 involved working on several sites to fix various bugs and implement new site features. This was done using HTML, JS, SCSS, and PHP.</Typography>
            <br />
            <Typography>The most notable project I worked on was the implementation of a new poll plugin on a site to replace a defective one. This involved finding a new poll plugin to replace the old one, cleaning the DB of any instances of the old poll, implementing the new poll plugin to be used with a new DB to be used solely by this plugin. Prior to this work term, I had no knowledge of WordPress, PHP, or SQL. I learned these as I went along but I also used skills I had learned outside of this job/in school. Some of these include CSS, HTML, and JS.</Typography>
            <br />
            <Typography>Overall, I learned various skills: SQL, PHP, SCSS, JS, just to name a few, and these skills will be beneficial in my studies and future work terms. I hope to learn even more in my future work terms.</Typography>
            <EmployerEvaluation evaluation="Very Good"/>
        </Container>
    );
}

export default WorkTerm1;
