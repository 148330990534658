import "./App.css";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { ThemeContext, Theme } from "./components/ThemeContext";
import { ThemeProvider } from '@mui/material/styles';
import ErrorBoundary from "./components/ErrorBoundary";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Resume from "./pages/resume/Resume";
import Work from "./pages/work/Work";
import Contact from "./pages/contact/Contact";
import ShaquilleSadiq from "./pages/ShaquilleSadiq";
import PageNotFound from "./pages/PageNotFound";

const App = () => {
    const [theme, setTheme] = React.useState(Theme);
    const context = { theme, setTheme };

    return (
        <ThemeContext.Provider value={context} theme={theme}>
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <BrowserRouter>
                        <ShaquilleSadiq>
                            <Routes>
                                <Route exact path="/" element={<Home/>} />
                                <Route path="/about" element={<About/>} />
                                <Route path="/resume" element={<Resume/>} />
                                <Route path="/work" element={<Work/>} />
                                <Route path="/contact" element={<Contact/>} />
                                {/* temporary redirect */}
                                <Route path="/code/*" element={<Work/>} />
                                <Route exact path="/*" element={<PageNotFound/>} />
                            </Routes>
                        </ShaquilleSadiq>
                    </BrowserRouter>
                </ErrorBoundary>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}

export default App;
