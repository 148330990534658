import * as React from "react";
import { Grid, Typography } from "@mui/material";
import Accordion from "../../components/Accordion";
import WorkTerm1 from "./coop/WorkTerm1";
import WorkTerm2 from "./coop/WorkTerm2";
import WorkTerm3 from "./coop/WorkTerm3_4";

const Work = () => {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (
        <Grid container sx={{maxInlineSize: '100vw', padding: '1rem'}}>
            <Grid item xs={12}>
                {/* <h2>Projects</h2> */}
                <Typography variant="h2">Co-op Work Terms</Typography>
                {/* <Container> */}
                    <Accordion title='Work Term 1 - System1 Canada' expanded={expanded === 'wt1'} onChange={handleChange('wt1')} content={<WorkTerm1/>}/>
                    <Accordion title='Work Term 2 - System1 Canada' expanded={expanded === 'wt2'} onChange={handleChange('wt2')} content={<WorkTerm2/>}/>
                    <Accordion title='Work Term 3 & 4 - System1 Canada' expanded={expanded === 'wt3-4'} onChange={handleChange('wt3-4')} content={<WorkTerm3/>}/>
                {/* </Container> */}
            </Grid>
        </Grid>
    );
}

export default Work;
